import React, { useState, useEffect, useRef } from "react";

import { useIntl } from "gatsby-plugin-intl";
import { graphql } from "gatsby";
import { CaseFragment } from "../../fragments"

import Layout from "../../layouts/default-layout";

import PageHead from "../../components/pageHead";
import GetInTouch from "../../components/getInTouch";
import BodyBlockContent from "../../components/bodyBlockContent";
import TeaserMedia from "../../components/teaserMedia";
import TagList from "../../components/tagList";

import { extractImageFromData } from '../../lib/helpers';

const ExclusiveDetailPage = ({ data }) => {
  const intl = useIntl()
  const content = data.sanityExclusive;
  const blackTheme = Boolean(content.darktheme);
  
  const password = content.password
  
  const title = content.title ? content.title[intl.locale] : '';
  const metaTitle = content.meta_title ? content.meta_title[intl.locale] : title;
  const metaDescription = content.meta_description ? content.meta_description[intl.locale] : content.subline ? content.subline[intl.locale] : null;  
  
  const locked = password ? true : false
  
  const [showbody, setShowbody] = useState(!password)
  
  const submitPassword = function (e) {
    e.preventDefault()
    const pw = e.target.elements[0].value
    if (pw === password) {
      setShowbody(true)
    }
  }
  
  return (
    <Layout customClass={`${blackTheme ? 'bg-black' : ''}`} textWhite={content.lightHeader} noIndex={true} title={metaTitle} description={metaDescription} og_image={extractImageFromData(content, 'poster')}>
      <PageHead
        locked={locked}
        topline={content.customer && content.customer.customer ? content.customer.customer.title[intl.locale] : null}
        headline={title}
        subline={content.subline && content.subline[intl.locale]}
        backgroundImage={extractImageFromData(content, 'poster')}
        backgroundVideo={content.postervideo}
        textWhite={content.lightHeader}
      />
      
      {!showbody && (
        <div className={`py-4 tro-body-block-content ${blackTheme ? 'tro-body-block-content--dark' : ''}`}>
          <div className={`col-span-full tro-grid-responsive `}>
            <div className="col-start-1 col-span-full tablet:col-start-4 tablet:col-span-6">
              <div className="px-4 py-4 lg:px-0 medium:py-16">
                <div className="text-center">
                  <form action="/" method="post" onSubmit={submitPassword}>
                    <label className="block pb-0 font-mono text-xs opacity-50 medium:pb-1 text-dark medium:text-sm" htmlFor="pwinput"><small>{ intl.formatMessage({ id: "passwordLabel" }) }</small></label>
                    <div className="flex flex-col">
                      <input className="flex-grow px-4 py-2 text-xs text-center text-black border-gray-400 rounded-full outline-none medium:text-sm border-1 focus:border-black" type="password" name="pw" id="pwinput" required />
                      <button className="self-center flex-none mt-2 ml-0 text-xs tro-button-cta medium:ml-4 medium:text-sm" type="submit p-2 rounded-lg ">{ intl.formatMessage({ id: "passwordButton" }) }</button>
                    </div>
                  </form>  
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      
      {showbody && (
        <div className={`mb-16`}>
          <div className="tro-default-padding-x tro-projects-list-container">
            {/* replace taglist below with own filter component */}
            <TagList
              tags={ content.tags }
              blackTheme={blackTheme}
            />
          </div>
        </div>
      )}
      
      {showbody && content._rawBody && (
        <BodyBlockContent blackTheme={blackTheme} data={content._rawBody[intl.locale]} />
      )}
      
      {showbody && content.credits && content.credits.length > 0 && (
        <div className="mb-16 tro-default-padding-x">
          <div className="tro-grid-responsive">
            {content.credits.map((el, index) => {
              return (
                <div key={`credit${index}`} className={`p-4 mb-6 font-mono text-sm border-l-8 min-h-88px medium:min-h-124px col-span-full medium:col-span-3 border-brand
                ${blackTheme ? 'text-white bg-white bg-opacity-10' : 'bg-brand-lighter '}`}>
                  {el.department && (
                    <span className="text-xs font-bold medium:text-special">{el.department[intl.locale]}</span>
                  )}
                  
                  {el.people.split("\n").map((p, index2) => (
                    <span className="block text-xs medium:text-special" key={index2}>{p}</span>
                  ))}
                </div>
              )
            })}
            
          </div>
        </div>
      )}
    
    </Layout>
  );
};

export default ExclusiveDetailPage;

export const query = graphql`
  query exclusive ($id: String) {
    sanityExclusive(id: { eq: $id }) {
      ... Exclusive
      meta_title {
        de
        en
      }
      meta_description {
        de
        en
      }
    }
    allSanityFileAsset {
      nodes {
        id
        url
      }
    }
  }
`
